import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../../global/Header';
import axios from 'axios';
import { useContext } from 'react';
import { AdminContext } from '../../context/AdminContext';
import AdminBox from './AdminBox';
import AdminDialog from './AdminDialog';
import { Formik } from 'formik';
import * as yup from "yup";
import { FormattedMessage } from 'react-intl';

function AdminsPage() {
    const { admin, setAdmin, token } = useContext(AdminContext);
    const [admins, setAdmins] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [targetAdmin, setTargetAdmin] = useState(null);

    const [dialogPasswordUpdateOpen, setPasswordUpdateDialogOpen] = useState(false);
    const [targetAdminToUpdatePassword, setTargetAdminToUpdatePassword] = useState(null);
    const [dialogEmailUpdateOpen, setEmailUpdateDialogOpen] = useState(false);
    const [targetAdminToUpdateEmail, setTargetAdminToUpdateEmail] = useState(null);
    const [dialogSecretsUpdateOpen, setSecretsUpdateDialogOpen] = useState(false);
    const [targetAdminToUpdateSecrets, setTargetAdminToUpdateSecrets] = useState(null);

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [adminToRemove, setAdminToRemove] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const superAdminMenuOpen = Boolean(anchorEl);
    const handleSuperAdminMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSuperAdminMenuClose = () => {
        setAnchorEl(null);
    };

    const fetchAdmins = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getAdmins`, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
                setAdmins([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setAdmins(response.data.admins.filter((adm) => adm.id !== admin.id));
                }
            });
    }

    useEffect(() => {
        fetchAdmins();
    }, []);

    const handleFormSubmit = async (values) => {
        if (targetAdmin) {
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateAdmin?adminId=${targetAdmin.id}`, {
                admin: {
                    ...values
                }
            }, { headers: { token: token } }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchAdmins();
                    handleClose();
                }
            });
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createAdmin`, {
                ...values
            }, { headers: { token: token } }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchAdmins();
                    handleClose();
                } else if (response && response.data) {
                    if (response.data.status === 406) {
                        //TODO open snackbar and show username already exists message
                    }
                }
            });
        }
    }


    var passwordChangeInitialValues = {
        password: ""
    }

    var validationObject = {
        password: yup.string().required(<FormattedMessage id="required" />)
    }

    const passwordChangeSchema = yup.object().shape(validationObject);

    const handlePasswordUpdateFormSubmit = async (values) => {
        if (targetAdminToUpdatePassword) {
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updatePassword?adminId=${targetAdminToUpdatePassword.id}`, {
                ...values
            }, { headers: { token: token } }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (targetAdminToUpdatePassword.id === admin.id) {
                        //do nothing
                    } else {
                        fetchAdmins();
                    }
                    handlePasswordUpdateDialogClose();
                }
            });
        }
    }

    var emailChangeInitialValues = {
        email: ""
    }

    var emailChangeValidationObject = {
        email: yup.string().required(<FormattedMessage id="required" />)
    }

    const emailChangeSchema = yup.object().shape(emailChangeValidationObject);

    const handleEmailUpdateFormSubmit = async (values) => {
        if (targetAdminToUpdateEmail) {
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateAdmin?adminId=${targetAdminToUpdateEmail.id}`, {
                admin: {
                    ...values
                }
            }, { headers: { token: token } }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setAdmin((prev) => ({ ...prev, email: values.email }));
                    handleEmailUpdateDialogClose();
                }
            });
        }
    }

    var secretsChangeInitialValues = {
        secret_question: "",
        secret_answer: ""
    }

    var secretsChangeValidationObject = {
        secret_question: yup.string().required(<FormattedMessage id="required" />),
        secret_answer: yup.string().required(<FormattedMessage id="required" />)
    }

    const secretsChangeSchema = yup.object().shape(secretsChangeValidationObject);

    const handleSecretsUpdateFormSubmit = async (values) => {
        if (targetAdminToUpdateSecrets) {
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateAdmin?adminId=${targetAdminToUpdateSecrets.id}`, {
                admin: {
                    ...values
                }
            }, { headers: { token: token } }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleSecretsUpdateDialogClose();
                }
            });
        }
    }

    useEffect(() => {
        if (targetAdmin) {
            setDialogOpen(true);
        }
    }, [targetAdmin]);

    const handleClose = async () => {
        setDialogOpen(false);
        setTargetAdmin(null);
    }

    const updateAdmin = (admin) => {
        setTargetAdmin(admin);
    }



    useEffect(() => {
        if (targetAdminToUpdatePassword) {
            setPasswordUpdateDialogOpen(true);
        }
    }, [targetAdminToUpdatePassword]);

    const handlePasswordUpdateDialogClose = async () => {
        setPasswordUpdateDialogOpen(false);
        setTargetAdminToUpdatePassword(null);
    }

    const updateAdminPassword = (admin) => {
        setTargetAdminToUpdatePassword(admin);
    }

    useEffect(() => {
        if (targetAdminToUpdateEmail) {
            setEmailUpdateDialogOpen(true);
        }
    }, [targetAdminToUpdateEmail]);

    const handleEmailUpdateDialogClose = async () => {
        setEmailUpdateDialogOpen(false);
        setTargetAdminToUpdateEmail(null);
    }

    const updateAdminEmail = (admin) => {
        setTargetAdminToUpdateEmail(admin);
    }

    useEffect(() => {
        if (targetAdminToUpdateSecrets) {
            setSecretsUpdateDialogOpen(true);
        }
    }, [targetAdminToUpdateSecrets]);

    const handleSecretsUpdateDialogClose = async () => {
        setSecretsUpdateDialogOpen(false);
        setTargetAdminToUpdateSecrets(null);
    }

    const updateAdminSecrets = (admin) => {
        setTargetAdminToUpdateSecrets(admin);
    }


    useEffect(() => {
        if (adminToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [adminToRemove]);

    const handleRemoveDialogClose = async () => {
        setRemoveDialogOpen(false);
        setAdminToRemove(null);
    }

    const handleAdminToRemove = (admin) => {
        if (admin) {
            setAdminToRemove(admin);
        }
    }

    const removeAdmin = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteAdmin?adminId=${adminToRemove.id}`, { headers: { "token": token } }).catch((err) => {
            console.log("err: " + err);
        }, { headers: { token: token } }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                fetchAdmins();
                handleRemoveDialogClose();
            }
        });
    }

    return (
        <Box sx={{ p: "75px" }}>
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={superAdminMenuOpen}
                onClose={handleSuperAdminMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    updateAdminEmail(admin);
                    handleSuperAdminMenuClose();
                }}>
                    <FormattedMessage id="change_email_address" />
                </MenuItem>
                <MenuItem onClick={() => {
                    updateAdminPassword(admin);
                    handleSuperAdminMenuClose();
                }}>
                    <FormattedMessage id="change_password" />
                </MenuItem>
                <MenuItem onClick={() => {
                    updateAdminSecrets(admin);
                    handleSuperAdminMenuClose()
                }}>
                    <FormattedMessage id="change_sercret_question_and_answer" />
                </MenuItem>
            </Menu>
            <AdminDialog targetAdmin={targetAdmin} dialogOpen={dialogOpen} handleClose={handleClose} handleFormSubmit={handleFormSubmit} />

            <Dialog open={dialogPasswordUpdateOpen} onClose={(e, reason) => {
                if (reason !== "backdropClick") {
                    handlePasswordUpdateDialogClose();
                }
            }}>
                <DialogTitle>
                    <FormattedMessage id="change_password" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="change_password_description" />
                    </DialogContentText>
                    <Formik
                        onSubmit={handlePasswordUpdateFormSubmit}
                        initialValues={passwordChangeInitialValues}
                        validationSchema={passwordChangeSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" justifyContent="center" mx="25px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={<FormattedMessage id="password" />}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"password"}
                                        value={values.password}
                                        error={!!touched.password && !!errors.password}
                                        helperText={touched.password && errors.password}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="error" variant="contained" onClick={handlePasswordUpdateDialogClose}>
                                        <FormattedMessage id="cancel" />
                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id="save" />

                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            <Dialog open={dialogEmailUpdateOpen} onClose={(e, reason) => {
                if (reason !== "backdropClick") {
                    handleEmailUpdateDialogClose();
                }
            }}>
                <DialogTitle>
                    <FormattedMessage id="change_email_address" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="change_email_address_description" />
                    </DialogContentText>
                    <Formik
                        onSubmit={handleEmailUpdateFormSubmit}
                        initialValues={emailChangeInitialValues}
                        validationSchema={emailChangeSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" justifyContent="center" mx="25px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={<FormattedMessage id="email" />}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"email"}
                                        value={values.email}
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="error" variant="contained" onClick={handleEmailUpdateDialogClose}>
                                        <FormattedMessage id="cancel" />
                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id="save" />

                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            <Dialog open={dialogSecretsUpdateOpen} onClose={(e, reason) => {
                if (reason !== "backdropClick") {
                    handleSecretsUpdateDialogClose();
                }
            }}>
                <DialogTitle>
                    <FormattedMessage id="change_sercret_question_and_answer" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="change_sercret_question_and_answer_description" />
                    </DialogContentText>
                    <Formik
                        onSubmit={handleSecretsUpdateFormSubmit}
                        initialValues={secretsChangeInitialValues}
                        validationSchema={secretsChangeSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" flexDirection="column" justifyContent="center" mx="25px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={<FormattedMessage id="secret_question" />}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"secret_question"}
                                        value={values.secret_question}
                                        error={!!touched.secret_question && !!errors.secret_question}
                                        helperText={touched.secret_question && errors.secret_question}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={<FormattedMessage id="secret_answer" />}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"secret_answer"}
                                        value={values.secret_answer}
                                        error={!!touched.secret_answer && !!errors.secret_answer}
                                        helperText={touched.secret_answer && errors.secret_answer}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="error" variant="contained" onClick={handleSecretsUpdateDialogClose}>
                                        <FormattedMessage id="cancel" />
                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id="save" />

                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <FormattedMessage id="are_you_sure_to_delete_admin" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id="delete_admin_description" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button onClick={() => removeAdmin()}>
                        <FormattedMessage id="delete" />
                    </Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" alignItems="center" gap="20px">
                <Header
                    title={<FormattedMessage id="admins.header.title" />}
                    subtitle={<FormattedMessage id="admins.header.description" />} />

                {admin.admin_edit_perm === 1 &&
                    <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                        setDialogOpen(true);
                    }} >
                        <FormattedMessage id="add_new_admin" />
                    </Button>
                }
                {admin.is_super === 1 &&
                    <Button variant='contained' color='info' sx={{ m: 1, width: '35ch' }} onClick={handleSuperAdminMenuClick} >
                        <FormattedMessage id="super_admin_settings" />
                    </Button>
                }

            </Box>
            <Box mt="30px">
                <Box display="flex" flexWrap="wrap" gap="20px">
                    {admins.map(adm =>
                        <AdminBox
                            key={adm.id}
                            adminModel={adm}
                            updateAdmin={updateAdmin}
                            updateAdminPassword={updateAdminPassword}
                            removeAdmin={handleAdminToRemove} />)}
                </Box>
            </Box>
        </Box>
    )
}

export default AdminsPage
