import { Box, Button, Snackbar, Typography } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import AlertDialogSlide from '../../components/AlertDialogSlide';
import { Table } from '../../components/Table';
import { FormattedMessage, useIntl } from 'react-intl';
import ForbiddenWordDialog from './ForbiddenWordDialog';
import { tokens } from '../../theme';
import dayjs from 'dayjs';
import ChangeDayIntervalForForbiddenWordCheckDialog from '../../components/ChangeDayIntervalForForbiddenWordCheckDialog';

function ForbiddenWordsPage() {
    const { admin, token } = useContext(AdminContext);
    const colors = tokens();
    const { formatMessage } = useIntl();
    const [forbiddenWords, setForbiddenWords] = useState([]);
    const [settings, setSettings] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [orderModel, setOrderModel] = useState(null);
    const [filterModel, setFilterModel] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        rowsPerPage: admin.forbidden_words_table_row_count
    });
    const [forbiddenWordEvent, setForbiddenWordEvent] = useState({ forbiddenWord: null, type: null });
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [changeDayIntervalDialogOpen, setChangeDayIntervalDialogOpen] = useState(false);
    const [snackbarState, setSnackbarState] = useState({ open: false, message: "" });
    const [alertDialogState, setAlertDialogState] = useState({
        open: false,
        title: "",
        description: "",
        positiveAnswer: "",
        negativeAnswer: "",
        onApprove: () => { }
    });
    const handleSnackbarOpen = (message) => {
        setSnackbarState({ open: true, message: message });
    };

    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, message: "" });
    };

    const columns = [
        {
            id: 'id',
            name: <FormattedMessage id={"table.columns.id"} />,
            type: 'number',
            sortable: true, filterable: true
        }, {
            id: 'word',
            name: <FormattedMessage id={"forbidden_words.table.columns.word"} />,
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: "created_date",
            name: <FormattedMessage id={"table.columns.created_date"} />,
            type: 'date',
            sortable: true, filterable: true,
            render: (value, row) => {
                return new Date(value).toLocaleDateString();
            }
        }, {
            id: 'actions',
            name: <FormattedMessage id={"table.columns.actions"} />,
            type: 'actions',
            sortable: false, filterable: false,
            getActions: () => [
                {
                    label: <FormattedMessage id={"edit"} />,
                    action: (row) => {
                        setForbiddenWordEvent({
                            forbiddenWord: row, type: "edit"
                        });
                    }
                },
                {
                    label: <FormattedMessage id={"delete"} />,
                    action: (row) => {
                        setForbiddenWordEvent({ forbiddenWord: row, type: "delete" })
                    }
                }
            ]
        }
    ];

    const fetchForbiddenWords = (paginationModel, orderModel, filterModel, token) => {
        setLoading(true);
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getForbiddenWords`,
            {
                startIndex: paginationModel.page * paginationModel.rowsPerPage,
                limit: paginationModel.rowsPerPage,
                orderBy: orderModel ? orderModel.orderBy : null,
                orderType: orderModel ? orderModel.orderType : null,
                filter: filterModel ? filterModel : null
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                setForbiddenWords([]);
                setLoading(false);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setForbiddenWords(response.data.forbiddenWords);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    };

    const fetchSettings = (token) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getSettings`, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
                setSettings(null);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setSettings(response.data.settings);
                }
            });
    };

    useEffect(() => {
        fetchForbiddenWords(paginationModel, orderModel, filterModel, token);
    }, [paginationModel, orderModel, filterModel, token]);

    useEffect(() => {
        if (token) {
            fetchSettings(token);
        }
    }, [token]);

    useEffect(() => {
        if ((forbiddenWordEvent?.forbiddenWord && forbiddenWordEvent?.type === "edit") || (!forbiddenWordEvent?.forbiddenWord && forbiddenWordEvent?.type === "add")) {
            setEditDialogOpen(true);
        }
        else if (forbiddenWordEvent?.forbiddenWord && forbiddenWordEvent?.type === "delete") {
            setAlertDialogState({
                open: true,
                title: <FormattedMessage id={"are_you_sure_to_delete_forbidden_word"} />,
                description: <FormattedMessage id={"delete_forbidden_word_description"} />,
                positiveAnswer: <FormattedMessage id={"delete"} />,
                negativeAnswer: <FormattedMessage id={"cancel"} />,
                onApprove: () => {
                    removeForbiddenWord(forbiddenWordEvent.forbiddenWord);
                }
            });
        }
    }, [forbiddenWordEvent]);

    const addForbiddenWords = async (forbiddenWords) => {
        await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createForbiddenWords`, {
            forbiddenWords: forbiddenWords
        }, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setForbiddenWordEvent({ forbiddenWord: null, type: null });
                    setEditDialogOpen(false);
                    fetchForbiddenWords(paginationModel, orderModel, filterModel, token);
                    handleSnackbarOpen(<FormattedMessage id={"forbidden_words_added_successfully"} />);
                }
            });
    }

    const updateForbiddenWord = async (forbiddenWord) => {
        var forbiddenWordIx = forbiddenWords.findIndex(o => o.id === forbiddenWordEvent.forbiddenWord.id);

        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateForbiddenWord?forbiddenWordId=${forbiddenWordEvent.forbiddenWord.id}`, {
            forbiddenWord: forbiddenWord
        }, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (forbiddenWordIx >= 0) {
                        forbiddenWords[forbiddenWordIx].word = forbiddenWord.word;
                        setForbiddenWords(Array.from(forbiddenWords));
                    }
                    setForbiddenWordEvent({ forbiddenWord: null, type: null });
                    setEditDialogOpen(false);
                    handleSnackbarOpen(<FormattedMessage id={"forbidden_word_updated_successfully"} />);
                }
            });
    }

    const handleForbiddenWordFormSubmit = async (values) => {
        if (forbiddenWordEvent.type === "add") {
            addForbiddenWords(values.word.split(",").map(w => ({ word: w })));
        } else if (forbiddenWordEvent.type === "edit") {
            updateForbiddenWord(values);
        }
    }

    const removeForbiddenWord = (forbiddenWord) => {
        axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteForbiddenWord?forbiddenWordId=${forbiddenWord.id}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setForbiddenWordEvent({ forbiddenWord: null, type: null })
                setAlertDialogState({
                    open: false,
                    title: "",
                    description: "",
                    positiveAnswer: "",
                    negativeAnswer: "",
                    onApprove: () => { }
                });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = forbiddenWords.findIndex(({ id }) => id === forbiddenWord.id);
                    var newForbiddenWords = Array.from(forbiddenWords);
                    if (ix >= 0) {
                        newForbiddenWords.splice(ix, 1);
                        setForbiddenWords(newForbiddenWords);
                    }
                    handleSnackbarOpen(<FormattedMessage id={"forbidden_word_deleted_successfully"} />);
                }
            });
    }

    const updateSettings = (settings) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateSettings`, {
            settings: settings
        }, {
            headers: {
                token: token
            }
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                setSettings((prev) => ({ ...prev, ...settings }));
                handleSnackbarOpen(formatMessage({ id: "settings_updated_successfully" }));
                setChangeDayIntervalDialogOpen(false);
            }
        });
    };

    const runWordCheck = () => {
        setSettings((prev) => ({ ...prev, last_forbidden_keyword_check_date: dayjs().format("YYYY-MM-DD") }));
        handleSnackbarOpen(formatMessage({ id: "forbidden_word_check_started" }));
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/runForbiddenWordCheck`, {}, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {

                }
            });
    }

    return (
        <Box sx={{ p: "75px" }}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                message={snackbarState.message}
            />
            {
                alertDialogState?.open &&
                <AlertDialogSlide
                    open={alertDialogState.open}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        setAlertDialogState({
                            open: false,
                            title: "",
                            description: "",
                            positiveAnswer: "",
                            negativeAnswer: "",
                            onApprove: () => { }
                        });
                    }}
                    clickPositiveAnswer={() => {
                        alertDialogState.onApprove();
                    }}
                    clickNegativeAnswer={() => { }}
                    positiveAnswer={alertDialogState.positiveAnswer}
                    negativeAnswer={alertDialogState.negativeAnswer}
                    title={alertDialogState.title}
                    description={alertDialogState.description}
                />
            }
            {
                ((forbiddenWordEvent.forbiddenWord && forbiddenWordEvent.type === "edit") || (!forbiddenWordEvent.forbiddenWord && forbiddenWordEvent.type === "add")) &&
                <ForbiddenWordDialog
                    dialogOpen={editDialogOpen}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        setEditDialogOpen(false);
                        setForbiddenWordEvent({ forbiddenWord: null, type: null });
                    }}
                    forbiddenWord={forbiddenWordEvent.forbiddenWord}
                    handleFormSubmit={handleForbiddenWordFormSubmit}
                />
            }
            {settings && settings.day_interval_for_forbidden_keyword_check !== undefined && settings.day_interval_for_forbidden_keyword_check !== null &&
                <ChangeDayIntervalForForbiddenWordCheckDialog
                    dialogOpen={changeDayIntervalDialogOpen}
                    handleClose={() => setChangeDayIntervalDialogOpen(false)}
                    handleFormSubmit={(values) => {
                        setAlertDialogState({
                            open: true,
                            title: <FormattedMessage id={"are_you_sure_to_change_day_interval"} />,
                            description: <FormattedMessage id={"change_day_interval_description"} />,
                            positiveAnswer: <FormattedMessage id={"yes"} />,
                            negativeAnswer: <FormattedMessage id={"cancel"} />,
                            onApprove: () => {
                                updateSettings(values);
                            }
                        });
                    }}
                    settings={settings}
                />
            }
            <Box display="flex" alignItems="center" gap="30px">
                <Header
                    title={<FormattedMessage id={"forbidden_words.header.title"} />}
                    subtitle={<FormattedMessage id={"forbidden_words.header.description"} />}
                />
                <Button variant="contained" color="success" onClick={() => setForbiddenWordEvent({ forbiddenWord: null, type: "add" })}>
                    <FormattedMessage id={"add_new_forbidden_words"} />
                </Button>
                <Button variant="contained" color="info" onClick={() => setChangeDayIntervalDialogOpen(true)}>
                    <FormattedMessage id={"change_day_interval"} />
                </Button>
                <Button variant="contained" color="info" onClick={() => setAlertDialogState({
                    open: true,
                    title: <FormattedMessage id={"are_you_sure_to_run_forbidden_word_check"} />,
                    description: <FormattedMessage id={"run_forbidden_word_check_description"} />,
                    positiveAnswer: <FormattedMessage id={"run"} />,
                    negativeAnswer: <FormattedMessage id={"cancel"} />,
                    onApprove: () => {
                        runWordCheck();
                    }
                })}>
                    <FormattedMessage id={"run_forbidden_word_check"} />
                </Button>
            </Box>
            {settings &&
                <Typography variant='h5' color={colors.grey[400]}>
                    <FormattedMessage id={"last_check_complation"} values={{ date: dayjs(settings.last_forbidden_keyword_check_date).format("DD/MM/YYYY") }} />
                </Typography>
            }
            <Table
                table_id={"forbidden_words_table"}
                isLoading={isLoading}
                columns={columns}
                rows={forbiddenWords}
                rowCount={rowCount}
                onOrderChange={(order) => {
                    setOrderModel(order);
                }}
                onPaginationChange={(pagination) => {
                    setPaginationModel(pagination);
                }}
                onFilterChange={(filter) => {
                    setFilterModel(filter);
                }}
                onRowClick={(row, column) => {

                }}
            />

        </Box>
    )
}

export default ForbiddenWordsPage